.login-layout-container {
    .layout-bg-container {
        overflow: hidden;
        position: relative;

        .layout-bg-video {
            position: absolute;
            right: 0;
            bottom: 0;
            min-width: 100%;
            min-height: 100%;
        }

        .layout-bg-image {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
        }

        .layout-bg-overlay {
            box-shadow: inset 0 0 0 1000px rgba(232, 55, 130, .35);
            position: absolute;
            right: 0;
            bottom: 0;
            min-width: 100%;
            min-height: 100%;
        }
    }

    .layout-bg-container,
    .layout-child-container {
        opacity: 0;
        animation-name: fade-in;
        animation-timing-function: cubic-bezier(0.75, 0, 0.175, 1);
        animation-duration: 0.75s;
        animation-fill-mode: forwards;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}