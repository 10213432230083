.login-page-container {
    height: 100%;
    padding: 50px 100px;
    text-align: center;

    .login-page-logo {
        width: 100%;
        margin-bottom: 35px;
    }

    .login-page-heading {
        letter-spacing: 1.5px;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 35px;
    }

    .login-page-button {
        height: 40px;
        font-size: 1rem;
    }
}