.webchat-message-container {
    margin-bottom: 15px;

    .webchat-message {
        border-radius: 5px;
        padding: 15px;

        .message-user-info {
            display: block;
            font-size: 0.85rem;
            font-style: italic;
            font-weight: 600;
        }

        .message {
            display: block;
            font-size: 0.85rem;
            font-weight: 400;
        }
    }
}